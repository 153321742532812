<p-menu #menu appendTo="body" [model]="contextMenuItems" [popup]="true"></p-menu>

<div class="grid">
    <div class="col-12 p-0">
        <p-accordion [activeIndex]="0">
            <p-accordionTab header="Search Orders/Trades">
                <div class="grid">
                    <div class="col-6 lg:col-2 md:col-4 sm:col-6">
                        <p-inputNumber [(ngModel)]="tradeId" placeholder="ID" [useGrouping]="false"> </p-inputNumber>
                    </div>
                    <div class="col-6 lg:col-1 md:col-4 sm:col-6">
                        <p-calendar [(ngModel)]="onDate" placeholder="On" [showClear]="true" (onSelect)="onOnDateChange()" [style]="{ width: '100%' }"></p-calendar>
                    </div>
                    <div class="col-6 lg:col-1 md:col-4 sm:col-6">
                        <p-calendar [(ngModel)]="fromDate" placeholder="From" [showClear]="true" (onSelect)="onFromToDateChange()" [style]="{ width: '100%' }"></p-calendar>
                    </div>
                    <div class="col-6 lg:col-1 md:col-4 sm:col-6">
                        <p-calendar [(ngModel)]="toDate" placeholder="To" [showClear]="true" (onSelect)="onFromToDateChange()" [style]="{ width: '100%' }"></p-calendar>
                    </div>
                    <div class="col-6 lg:col-1 md:col-4 sm:col-6">
                        <p-dropdown [(ngModel)]="product" placeholder="Product" [showClear]="true" [options]="products" optionLabel="displayName" [showClear]="true" 
                        [style]="{ width: '100%' }" [filter]="true" filterBy="displayName" [resetFilterOnHide]="true"
                        [virtualScroll]="true" [virtualScrollItemSize]="38" [loading]="loading.includes('products')" appendTo="body"></p-dropdown>
                    </div>
                    <div class="col-6 lg:col-2 md:col-4 sm:col-6">
                        <p-dropdown [(ngModel)]="client" placeholder="Client" [options]="clients" optionLabel="clientAlias" [showClear]="true" 
                        [filter]="true" filterBy="clientAlias" [resetFilterOnHide]="true" [style]="{ width: '100%' }" 
                        [virtualScroll]="true" [virtualScrollItemSize]="38" [loading]="loading.includes('clients')" appendTo="body"></p-dropdown>
                    </div>
                    <div class="col-6 lg:col-1 md:col-6 sm:col-6">
                        <p-dropdown [(ngModel)]="trader" [options]="traders" optionLabel="fullName" [filter]="true" filterBy="fullName" 
                        [resetFilterOnHide]="true" placeholder="Trader" [showClear]="true" [style]="{ width: '100%' }"
                        [virtualScroll]="true" [virtualScrollItemSize]="38" [loading]="loading.includes('traders')" appendTo="body"></p-dropdown>
                    </div>
                    <div class="col-6 lg:col-3 md:col-6 sm:col-6">
                        <button pButton type="button" label="Search Orders" class="p-button-sm mr-1 mb-1" icon="pi pi-search"></button>
                        <button pButton type="button" label="Search Trades" class="p-button-sm mr-1 mb-1" icon="pi pi-search"></button>
                        <button pButton type="button" label="Reset Filters" (click)="resetFilters()" class="p-button-sm p-button-warning mb-1" icon="pi pi-refresh"></button>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="col-12 p-0 pt-4">
        <p-accordion [activeIndex]="0">
            <p-accordionTab header="My Last 10 Orders">
                <div class="col-12 p-0">
                    <p-table #MyLast10Orders [value]="myLast10Orders" [loading]="false" [globalFilterFields]="['calendarName', 'somethingelse']" selectionMode="single" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [style]="{'width': '2%'}"></th>
                                <th [style]="{'width': '5%'}">ID</th>
                                <th [style]="{'width': '10%'}">Date</th>
                                <th [style]="{'width': '5%'}">Category</th>
                                <th [style]="{'width': '5%'}">Last Action</th>
                                <th [style]="{'width': '73%'}">Order Sentence</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-order>
                            <tr>
                                <td style="cursor:pointer" [pTooltip]="order.comment" (click)="showOrderTradeNotes(order)">
                                    <ng-container *ngIf="!order.notes || order.notes.length === 0">
                                        <i class="pi pi-book"></i>
                                    </ng-container>
                                    <ng-container *ngIf="order.notes && order.notes.length > 0">
                                        <i class="pi pi-comment"></i>
                                    </ng-container>
                                </td>
                                <td style="cursor:pointer" class="contextMenuLink" (click)="menu.toggle($event)">{{ order.id }}</td>
                                <td>{{ order.date | date: (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ?? 'yyyy/MM/dd') }}</td>
                                <td>{{ order.category }}</td>
                                <td>{{ order.lastAction }}</td>
                                <td [innerHTML]="sanitizer.bypassSecurityTrustHtml(order.sentence)"></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="col-12 p-0 pt-4">
        <p-accordion [activeIndex]="0">
            <p-accordionTab header="My Last 10 Trades/Fills">
                <div class="col-12 p-0">
                    <p-table #MyLast10Orders [value]="myLast10Orders" [loading]="false" [globalFilterFields]="['calendarName', 'somethingelse']" selectionMode="single" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [style]="{'width': '2%'}"></th>
                                <th [style]="{'width': '5%'}">ID</th>
                                <th [style]="{'width': '10%'}">Date</th>
                                <th [style]="{'width': '5%'}">Category</th>
                                <th [style]="{'width': '5%'}">Last Action</th>
                                <th [style]="{'width': '73%'}">Order Sentence</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-trade>
                            <tr>
                                <td style="cursor:pointer" [pTooltip]="trade.comment" (click)="showOrderTradeNotes(trade)">
                                    <i *ngIf='!trade.comment' class="pi pi-book" ></i>
                                    <i *ngIf='trade.comment' class="pi pi-comment"></i>
                                </td>
                                <td style="cursor:pointer" class="contextMenuLink" (click)="menu.toggle($event)">{{ trade.id }}</td>
                                <td>{{ trade.date | date: (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ?? 'yyyy/MM/dd') }}</td>
                                <td>{{ trade.category }}</td>
                                <td>{{ trade.lastAction }}</td>
                                <td [innerHTML]="sanitizer.bypassSecurityTrustHtml(trade.sentence)"></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="col-12 p-0 pt-4">
        <p-accordion>
            <p-accordionTab header="System Last 25 Orders">
                <div class="col-12 p-0">
                    <p-table #MyLast10Orders [value]="myLast10Orders" [loading]="false" [globalFilterFields]="['calendarName', 'somethingelse']" selectionMode="single" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [style]="{'width': '2%'}"></th>
                                <th [style]="{'width': '5%'}">ID</th>
                                <th [style]="{'width': '10%'}">Date</th>
                                <th [style]="{'width': '5%'}">Category</th>
                                <th [style]="{'width': '5%'}">Last Action</th>
                                <th [style]="{'width': '73%'}">Order Sentence</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-order>
                            <tr>
                                <td style="cursor:pointer" [pTooltip]="order.comment" (click)="showOrderTradeNotes(order)">
                                    <i *ngIf='!order.comment' class="pi pi-book" ></i>
                                    <i *ngIf='order.comment' class="pi pi-comment"></i>
                                </td>
                                <td style="cursor:pointer" class="contextMenuLink" (click)="menu.toggle($event)">{{ order.id }}</td>
                                <td>{{ order.date | date: (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ?? 'yyyy/MM/dd') }}</td>
                                <td>{{ order.category }}</td>
                                <td>{{ order.lastAction }}</td>
                                <td [innerHTML]="sanitizer.bypassSecurityTrustHtml(order.sentence)"></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="col-12 p-0 pt-4">
        <p-accordion>
            <p-accordionTab header="System Last 25 Trades/Fills">
                <div class="col-12 p-0">
                    <p-table #MyLast10Orders [value]="myLast10Orders" [loading]="false" [globalFilterFields]="['calendarName', 'somethingelse']" selectionMode="single" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [style]="{'width': '2%'}"></th>
                                <th [style]="{'width': '5%'}">ID</th>
                                <th [style]="{'width': '10%'}">Date</th>
                                <th [style]="{'width': '5%'}">Category</th>
                                <th [style]="{'width': '5%'}">Last Action</th>
                                <th [style]="{'width': '73%'}">Order Sentence</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-trade>
                            <tr>
                                <td style="cursor:pointer" [pTooltip]="trade.comment" (click)="showOrderTradeNotes(trade)">
                                    <i *ngIf='!trade.comment' class="pi pi-book" ></i>
                                    <i *ngIf='trade.comment' class="pi pi-comment" ></i>
                                </td>
                                <td style="cursor:pointer" class="contextMenuLink" (click)="menu.toggle($event)">{{ trade.id }}</td>
                                <td>{{ trade.date | date: (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ?? 'yyyy/MM/dd') }}</td>
                                <td>{{ trade.category }}</td>
                                <td>{{ trade.lastAction }}</td>
                                <td [innerHTML]="sanitizer.bypassSecurityTrustHtml(trade.sentence)"></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>

<p-dialog *ngIf="showOrderTradeNotesDialog" [(visible)]="showOrderTradeNotesDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{ width: '50vw' }"   header="Notes">
    <app-trade-order-notes [tradeOrder]="selectedTradeOrderNote" (closeNotes)="closeNotesDialog()"></app-trade-order-notes>
</p-dialog>