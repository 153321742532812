import { Component } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomApiService } from 'src/app/service/mom-api.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-documentation',
  standalone: true,
  imports: [AccordionModule, CommonModule, DropdownModule, FormsModule,
      ReactiveFormsModule],
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.scss'
})
export class DocumentationComponent {
  items: any[] = [];

  activeIndex: number = 0;
  githubCommitSha: string = '';
  githubDeploymentDate: string = '';
  apiOptions: any[] = [];
  apiOption: any;

  constructor(private momApiService: MomApiService, public userService: UserService) {}

  ngOnInit(): void {
    this.githubCommitSha = environment.githubCommitSha || 'N/A';
    this.githubDeploymentDate = environment.githubDeploymentDate || 'N/A';

      this.items = [
          {
              label: 'General',
              icon: 'pi pi-fw pi-info-circle',
              questions: ['Is there a trial period?', 'Do I need to sign up with credit card?', 'Is the subscription monthly or annual?', 'How many tiers are there?'],
              answers: ['test this and <a href="/ref-data/calendars" target="_blank">some screen</a>', 'test2', 'test3', 'test4']
          },
          {
              label: 'Mailing',
              icon: 'pi pi-fw pi-envelope',
              questions: ['How do I setup my account?', 'Is there a limit on mails to send?', 'What is my inbox size?', 'How can I add attachements?'],
              answers: ['test', 'test2', 'test3', 'test4']
          },
          {
              label: 'Support',
              icon: 'pi pi-fw pi-question-circle',
              questions: ['How can I get support?', 'What is the response time?', 'Is there a community forum?', 'Is live chat available?'],
              answers: ['test', 'test2', 'test3', 'test4']
          },
          {
              label: 'Billing',
              icon: 'pi pi-fw pi-credit-card',
              questions: ['Will I receive an invoice?', 'How to provide my billing information?', 'Is VAT included?', 'Can I receive PDF invoices?'],
              answers: ['test', 'test2', 'test3', 'test4']
          }
      ];

      this.apiOptions = [
        { label: 'Auto Failover (Recommended)', value: 'AUTO' },
        { label: 'Primary EU-Central-1', value: 'PRIMARY' },
        { label: 'Failover US-East-1', value: 'FAILOVER' }
      ];

      let option = localStorage.getItem('apiOption');
      if (option) {
        this.apiOption = this.apiOptions.find(opt => opt.value === option); // Rename 'option' to 'opt'
        console.log('option', option);
        console.log('apiOption', this.apiOption);
      } 
      else {
        this.apiOption = this.apiOptions[0];
        localStorage.setItem('apiOption', this.apiOption.value);
      }
  }

  changeDocumentationItem(i: number) {
      this.activeIndex = i;
  }

  onApiOptionChange(event: any) {
    localStorage.setItem('apiOption', event.value.value);
    window.location.reload();
  }
}
