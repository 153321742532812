<div class="flex-1 card p-0">
    <div class="flex flex-column h-full">
        <div class="flex align-items-center surface-border p-3 lg:p-6">
            <div class="flex align-items-center ml-auto" >
                <button pButton pRipple type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-outlined p-button-secondary"></button>
            </div>
        </div>
        <div class="p-3 md:px-4 lg:px-6 lg:py-4 mt-2 overflow-y-auto" style="max-height: 53vh;" #chatWindow [scrollTop]="chatWindow.scrollHeight">
            <div *ngFor="let message of messages">
                <div *ngIf="message.ownerId !== 123" class="grid grid-nogutter mb-4">
                    <div class="mt-1 mr-3">
                        <img src="assets/demo/images/avatar/test.png" alt="Name" class="w-3rem h-3rem border-circle shadow-4">
                    </div>
                    <div class="col mt-3">
                        <p class="text-900 font-semibold mb-3">Test</p>
                        <span class="text-700 inline-block font-medium border-1 surface-border p-3 white-space-normal border-round" style="word-break: break-word; max-width:80%;">{{message.text}}</span>
                        <p class="text-700 mt-3">{{parseDate(message.createdAt)}}<i class="pi pi-check text-green-400 ml-2" ></i></p>
                    </div>
                </div>
    
                <div *ngIf="message.ownerId === defaultUserId" class="grid grid-nogutter mb-4">
                    <div class="col mt-3 text-right">
                        <span class="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-3 white-space-normal border-round" style="word-break: break-word; max-width:80%;">{{message.text}}</span>
                        <p class="text-700 mt-3">{{parseDate(message.createdAt)}} <i class="pi pi-check text-green-400 mr-3" ></i></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-3 md:p-4 lg:p-6 flex flex-column sm:flex-row align-items-center mt-auto border-top-1 surface-border gap-3">
            <input id="message" type="text" pInputText placeholder="Type a message" class="flex-1 w-full sm:w-auto border-round" [(ngModel)]="textContent" (keydown.enter)="sendMessage()"/>
            <div class="flex w-full sm:w-auto gap-3">
                <button pButton pRipple icon="pi pi-paperclip" pTooltip="Upload file" tooltipPosition="bottom" class="p-button-secondary"></button>
                <button pButton pRipple label="Send" icon="pi pi-send" class="w-full sm:w-auto" (click)="sendMessage()"></button>
            </div>
        </div>
    </div>
</div>
