import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Client } from '../models/client';
import { ContractDateRule, PreciousLocations, PricingPeriodRule, Product, ProductAvailableMonth, ProductContract, ProductOrderType, ProductRingKerb, ProductUnit, QtyPeriodicity } from '../models/product-setup';
import { Entity } from '../models/entity';
import { Trader } from '../models/trader';
import { ClientGiveupBroker } from '../models/client-giveup-broker';
import { OrderBasis } from '../models/order-basis';
import { ClientPromptBasis } from '../models/client-prompt-basis';
import { ClientBusinessCategory } from '../models/client-business-categories';
import { ClientType } from '../models/client-type';
import { ClientCategory } from '../models/client-category';
import { UserClientCategoryBlacklistXRef } from '../models/user-client-category-blacklist-xref';
import { ClientProductXRef } from '../models/client-product-xref';
import { ClientContractXRef } from '../models/client-contract-xref';
import { ClientCurrencyXRef } from '../models/client-currency-xref';
import { ClientContractDateRuleOverride } from '../models/client-contract-date-rule-override';
import { ClientWhitelistRestrictionsXRef } from '../models/client-whitelist-restrictions-xref';
import { InPriceCommission, InPriceCommissionDefault } from '../models/in-price-commission-default';
import { BrokerageRuleDefault } from '../models/brokerage-rule-default';
import { DoddFrankTradeXRef } from '../models/dodd-frank-trade-xref';
import { DoddFrankInterEntity } from '../models/dodd-frank-inter-entity';
import { DateFormat, OrderEntryMonthsPref, UserProfile } from '../models/user-profile';
import { Contract } from '../models/contract';
import { Currency, Exchange, ExchangeCurrency } from '../models/exchange';
import { ClientContractDateRule } from '../models/client-contract-date-rule';
import { Commodity, CommodityType } from '../models/commodity';
import { Location } from '../models/locations';
import { orderManagementView, OrderMgtViewGroupMembership, OrderTemplate, OrderTemplateGroupMembership, UserGroup, UserGroupMembership } from '../models/user-group';
import { AccessLevel, Role, RoleFunctionMatrix } from '../models/role-function';
import { Function } from '../models/function';
import { CurrencyUnitDependencies, Unit, UnitConversions } from '../models/unit';
import { SalesforceUser } from '../models/salesforce-users';
import { RFQAlertTopic, RFQClientAgreements, RFQClientCurrency, RFQClientProducts, RFQClientTradingLimits, RFQLocaleXref, RFQPremiumProfiles, RFQProductDefaults, RFQRegion, RFQSalesForceClient, RFQTradingDefinition } from '../models/rfq-ref-data';
import { SourceSystem } from '../models/source-system';
import { OrderExpiry } from '../models/order-expiry';
import { ForwardCurve, FxCurve, IPCDefaults, IPCOperation, IPCOperationDefaults, IPCValue, PricingIndex, PricingIndexProductCurrency, Quote, QuoteType, ValuationLiquidContract, ValuationModel } from '../models/pricing';
import { DateRule } from '../models/date-rule';
import { OrderTypes } from '../models/order-types';
import { DisplayOption } from '../models/display-options';
import { OrderStyles } from '../models/order-styles';
import { PricingFrequency } from '../models/pricing-frequency';
import { OrderTypeContracts } from '../models/order-type-contracts';
import { OrderTypeBasis } from '../models/order-type-basis';
import { CalendarTimezones } from '../models/calendar-timezones';
import { Calendar } from '../models/calendar';
import { OrderBuySell } from '../models/order-buy-sell';
import { ClientOrderBasisBlacklist } from '../models/client-order-basis-blacklist';
import { ArbBlotterDefinitionType, ArbBlotterUserAccessType, BlotterDefinitionType, BlotterEODAccessType, BlotterNameType, BlotterSource, BlotterUserAccessType } from '../models/blotter';
import { EntityPortfolioOverride, EntityPortfolioWhitelist, PortfolioDefault, ProductPortfolio } from '../models/portfolio';
import { Region } from '../models/region-type';
import { TrinMomIntegrationTemplate } from '../models/trin-mom-integ-template';
import { TradeTxType, TrinMomContractMapping, TrinMomIntegrationAction } from '../models/trin-mom-contract-mapping';
import { HedgeRuleDefinition } from '../models/hedge-rule-definition';
import { MOMBrokerageCommissionRules } from '../models/mom-brokerage-commission-rules';

@Injectable({ providedIn: 'root' })
export class MomApiService {
  constructor(private http: HttpClient) {}

  getPrimaryApiUrl(): string {
    let apiOption = localStorage.getItem('apiOption');

    if (apiOption == 'PRIMARY') {
      return environment.primaryApiUrl;
    }
    else if (apiOption == 'FAILOVER') {
      return environment.secondaryApiUrl;
    }
    else {
      return environment.primaryApiUrl;
    }
  }

  getSecondaryApiUrl(): string {
    let apiOption = localStorage.getItem('apiOption');

    if (apiOption == 'PRIMARY') {
      return environment.primaryApiUrl;
    }
    else if (apiOption == 'FAILOVER') {
      return environment.secondaryApiUrl;
    }
    else {
      return environment.secondaryApiUrl;
    }
  }

  getData<T>(endpoint: string): Observable<T> {
    const primaryUrl = this.getPrimaryApiUrl() + '/' + endpoint;
    const secondaryUrl = this.getSecondaryApiUrl() + '/' + endpoint;
  
    return this.http.get<T>(primaryUrl).pipe(
      retry({ count: 1, delay: () => timer(1000) }), // Retry once with 1-second delay
      catchError(() => {
        // If the primary URL fails, fallback to the secondary URL
        return this.http.get<T>(secondaryUrl).pipe(
          catchError(error => {
            // Handle error from both attempts
            return throwError(error);
          })
        );
      })
    );
  }
  

  postData(endpoint: string, data: any) {
    const primaryUrl = this.getPrimaryApiUrl() + '/' + endpoint;
    const secondaryUrl = this.getSecondaryApiUrl() + '/' + endpoint;

    return this.http.post(primaryUrl, data)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .post(secondaryUrl, data)
            .pipe(
              catchError(error => {
                return throwError(error)
              })
            );
        })
      );
  }

  putData(endpoint: string, data: any) {
    const primaryUrl = this.getPrimaryApiUrl() + '/' + endpoint;
    const secondaryUrl = this.getSecondaryApiUrl() + '/' + endpoint;

    return this.http.put(primaryUrl, data)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .post(secondaryUrl, data)
            .pipe(
              catchError(error => {
                return throwError(error)
              }));
        })
      );
  }

  patchData(endpoint: string, data: any) {
    const primaryUrl = this.getPrimaryApiUrl() + '/' + endpoint;
    const secondaryUrl = this.getSecondaryApiUrl() + '/' + endpoint;

    return this.http.patch(primaryUrl, data)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .patch(secondaryUrl, data)
            .pipe(
              catchError(error => {
                return throwError(error)
              }));
        })
      );
  }

  deleteData(endpoint: string, id: any) {
    const primaryUrl = this.getPrimaryApiUrl() + '/' + endpoint + '/' + id;
    const secondaryUrl = this.getSecondaryApiUrl() + '/' + endpoint + '/' + id;

    return this.http.delete(primaryUrl)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .delete(secondaryUrl)
            .pipe(
              catchError(error => {
                return throwError(error)
              }));
        })
      );
  }

  getTrinMomIntegrationTemplates(activeOnly: boolean = true): Observable<TrinMomIntegrationTemplate[]> {
    const url = `refdata/TrinMomIntegrationTemplate/all?activeOnly=${activeOnly}`;
    return this.getData<TrinMomIntegrationTemplate[]>(`${url}`).pipe(
      map((response: TrinMomIntegrationTemplate[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getTraders(includeChildEntities: boolean = true,
    activeOnly: boolean = true): Observable<Trader[]> {
    const url = `refdata/trader/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Trader[]>(`${url}`).pipe(
      map((response: Trader[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.fullName.localeCompare(b.fullName));
      })
    );
  }

  getClients(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<Client[]> {
    const url = `refdata/client/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Client[]>(url).pipe(
      map((response: Client[]) => {
        // Sort and return the sorted data by clientAlias
        return response.sort((a, b) => a.clientAlias.localeCompare(b.clientAlias));
      })
    );
  }

  getPortfolioClients(activeOnly: boolean = true): Observable<Client[]> {
    const url = `refdata/client/portfolios`;
    return this.getData<Client[]>(`${url}`).pipe(
      map((response: Client[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.clientAlias.localeCompare(b.clientAlias));
      })
    );
  }

  getProducts(includeChildEntities: boolean = true, activeOnly: boolean = true): Observable<Product[]> {
    const url = `refdata/exchangecommodityxRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Product[]>(`${url}`).pipe(
      map((response: Product[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.displayName.localeCompare(b.displayName));
      })
    );
  }

  getCommodities(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<Commodity[]> {
    const url = `refdata/commodity/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Commodity[]>(url).pipe(
      map((response: Commodity[]) => {
        return response.sort((a, b) => a.commodityShortName.localeCompare(b.commodityShortName));
      })
    );
  }
  
  getCommodityTypes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<CommodityType[]> {
    const url = `refdata/commodityType/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<CommodityType[]>(url).pipe(
      map((response: CommodityType[]) => {
        return response.sort((a, b) => a.commodityTypeName.localeCompare(b.commodityTypeName));
      })
    );
  }
  

  getEntities(includeChildEntities: boolean = false, activeOnly: boolean = true): Observable<Entity[]> {
    const url = `refdata/entity/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    
    return this.getData<Entity[]>(url).pipe(
      map((response: Entity[]) => 
        // Sort by entityShortName in ascending order
        response.sort((a, b) => a.entityShortName.localeCompare(b.entityShortName))
      )
    );
  }
  

  getClientPromptBasis(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientPromptBasis[]> {
    const url = `refdata/clientPromptBasis/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientPromptBasis[]>(url).pipe(
      map((response: ClientPromptBasis[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientGiveupBroker(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientGiveupBroker[]> {
    const url = `refdata/clientGiveupBroker/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientGiveupBroker[]>(url).pipe(
      map((response: ClientGiveupBroker[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientType(activeOnly: boolean = true): Observable<ClientType[]> {
    const url = `refdata/clientType/all?activeOnly=${activeOnly}`;
    return this.getData<ClientType[]>(url).pipe(
      map((response: ClientType[]) => {
        return response.sort((a, b) => a.clientTypeName.localeCompare(b.clientTypeName));
      })
    );
  }
  
  getClientBusinessCategory( activeOnly: boolean = true): Observable<ClientBusinessCategory[]> {
    const url = `refdata/ClientBusinessCategory/all?activeOnly=${activeOnly}`;
    return this.getData<ClientBusinessCategory[]>(url).pipe(
      map((response: ClientBusinessCategory[]) => {
        return response.sort((a, b) => a.clientBusinessCategoryName.localeCompare(b.clientBusinessCategoryName));
      })
    );
  }

  getClientCategories( activeOnly: boolean = true): Observable<ClientCategory[]> {
    const url = `refdata/clientCategory/all?activeOnly=${activeOnly}`;
    return this.getData<ClientCategory[]>(url).pipe(
      map((response: ClientCategory[]) => {
        return response.sort((a, b) => a.clientCategoryName.localeCompare(b.clientCategoryName));
      })
    );
  }

  getRFQSalesforceClients(): Observable<RFQSalesForceClient[]> {
    return this.getData<RFQSalesForceClient[]>('refdata/RFQSalesforceClient/all').pipe(
      map((response: RFQSalesForceClient[]) => {
        return response.sort((a, b) => a.salesforceClientId.localeCompare(b.salesforceClientId));
      })
    );
  }

  getRFQPremiumProfiles(): Observable<RFQPremiumProfiles[]> {
    return this.getData<RFQPremiumProfiles[]>('refdata/RFQPremiumProfile/all').pipe(
      map((response: RFQPremiumProfiles[]) => {
        return response.sort((a, b) => a.profileName.localeCompare(b.profileName));
      })
    );
  }
  
  getUserClientCategoryBlacklistXRef(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<UserClientCategoryBlacklistXRef[]> {
    const url = `refdata/userClientCategoryBlacklistXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<UserClientCategoryBlacklistXRef[]>(url).pipe(
      map((response: UserClientCategoryBlacklistXRef[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientProductXRef(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientProductXRef[]> {
    const url = `refdata/clientProductXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientProductXRef[]>(url).pipe(
      map((response: ClientProductXRef[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientContractXRef(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientContractXRef[]> {
    const url = `refdata/clientContractXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientContractXRef[]>(url).pipe(
      map((response: ClientContractXRef[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientContractDateRuleOverride(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientContractDateRuleOverride[]> {
    const url = `refdata/clientContractDateRuleOverride/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientContractDateRuleOverride[]>(url).pipe(
      map((response: ClientContractDateRuleOverride[]) => {
        console.log(response);
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientCurrencyXRef(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientCurrencyXRef[]> {
    const url = `refdata/clientCurrencyXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientCurrencyXRef[]>(url).pipe(
      map((response: ClientCurrencyXRef[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientWhitelistRestrictionsXRef(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientWhitelistRestrictionsXRef[]> {
    const url = `refdata/clientWhitelistRestrictionsXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientWhitelistRestrictionsXRef[]>(url).pipe(
      map((response: ClientWhitelistRestrictionsXRef[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getBrokerageRuleDefaults(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<BrokerageRuleDefault[]> {
    const url = `refdata/brokerageRuleDefault/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<BrokerageRuleDefault[]>(url).pipe(
      map((response: BrokerageRuleDefault[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getDoddFrankTradeXRef(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<DoddFrankTradeXRef[]> {
    const url = `refdata/doddFrankTradeXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<DoddFrankTradeXRef[]>(url).pipe(
      map((response: DoddFrankTradeXRef[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getDoddFrankInterEntity(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<DoddFrankInterEntity[]> {
    const url = `refdata/doddFrankInterEntity/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<DoddFrankInterEntity[]>(url).pipe(
      map((response: DoddFrankInterEntity[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getCurrencies(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<Currency[]> {
    const url = `refdata/currency/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Currency[]>(url).pipe(
      map((response: Currency[]) => {
        return response.sort((a, b) => a.currencyShortName.localeCompare(b.currencyShortName));
      })
    );
  }
  
  getInPriceCommissionDefaults(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<InPriceCommissionDefault[]> {
    const url = `refdata/inPriceCommissionDefault/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<InPriceCommissionDefault[]>(url).pipe(
      map((response: InPriceCommissionDefault[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  

  getContractDateRules(): Observable<ClientContractDateRule[]> {
    return this.getData<ClientContractDateRule[]>('refdata/ContractDateRule/all').pipe(
      map((response: ClientContractDateRule[]) => {
        return response.sort((a, b) => a.dateRuleCode.localeCompare(b.dateRuleCode));
      })
    );
  }

  getInPriceCommissionDefault(): Observable<InPriceCommissionDefault[]> {
    return this.getData<InPriceCommissionDefault[]>('refdata/inPriceCommissionDefault/all').pipe(
      map((response: InPriceCommissionDefault[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getWhiteListRestrictions(): Observable<ClientWhitelistRestrictionsXRef[]> {
    return this.getData<ClientWhitelistRestrictionsXRef[]>('refdata/clientWhitelistRestrictionsXRef/all').pipe(
      map((response: ClientWhitelistRestrictionsXRef[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getInPriceCommissions(activeOnly: boolean = true): Observable<InPriceCommission[]> {
    const url = `refdata/inPriceCommission/all?activeOnly=${activeOnly}`;
    return this.getData<InPriceCommission[]>(url).pipe(
      map((response: InPriceCommission[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getOrderBasis(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<OrderBasis[]> {
    const url = `refdata/orderBasis/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderBasis[]>(url).pipe(
      map((response: OrderBasis[]) => {
        return response.sort((a, b) => a.orderBasisName.localeCompare(b.orderBasisName));
      })
    );
  }

  getLocations(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<Location[]> {
    const url = `refdata/location/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Location[]>(`${url}`).pipe(
      map((response: Location[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.locationName.localeCompare(b.locationName));
      })
    );
  };

  getUserGroups(activeOnly: boolean = true): Observable<UserGroup[]> {
    // Construct the URL dynamically based on the activeOnly parameter
    const url = `refdata/usergroup/all?activeOnly=${activeOnly}`;
  
    return this.getData<UserGroup[]>(url).pipe(
      map((response: UserGroup[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.userGroupName.localeCompare(b.userGroupName));
      })
    );
  }  

  getOrderTemplates(activeOnly: boolean = true): Observable<OrderTemplate[]> {
    // Construct the URL dynamically based on the activeOnly parameter
    const url = `refdata/orderTemplate/all?activeOnly=${activeOnly}`;
  
    return this.getData<OrderTemplate[]>(url).pipe(
      map((response: OrderTemplate[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.globalTemplateName.localeCompare(b.globalTemplateName));
      })
    );
  } 
  
  orderManagementViews(activeOnly: boolean = true): Observable<orderManagementView[]> {
    // Construct the URL dynamically based on the activeOnly parameter
    const url = `refdata/OrderManagementView/all?activeOnly=${activeOnly}`;
  
    return this.getData<orderManagementView[]>(url).pipe(
      map((response: orderManagementView[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.globalViewName.localeCompare(b.globalViewName));
      })
    );
  } 

  getRoles(activeOnly: boolean = true): Observable<Role[]> {
    const url = `refdata/role/all?activeOnly=${activeOnly}`;
    return this.getData<Role[]>(`${url}`).pipe(
      map((response: Role[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.roleName.localeCompare(b.roleName));
      })
    );
  }

  getFunctions(activeOnly: boolean = true): Observable<Function[]> {
    const url = `refdata/function/all?activeOnly=${activeOnly}`;
    return this.getData<Function[]>(`${url}`).pipe(
      map((response: Function[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.functionName.localeCompare(b.functionName));
      })
    );
  }

  getAccessLevels(activeOnly: boolean = true): Observable<AccessLevel[]> {
    const url = `refdata/accesslevel/all?activeOnly=${activeOnly}`;
    return this.getData<AccessLevel[]>(`${url}`).pipe(
      map((response: AccessLevel[]) => {
        // Sort and return the sorted data
        return response.sort((a, b) => a.accessLevelName.localeCompare(b.accessLevelName));
      })
    );
  }

  getUOM(activeOnly: boolean = true): Observable<Unit[]> {
    const url = `refdata/unit/all?activeOnly=${activeOnly}`;
    return this.getData<Unit[]>(`${url}`).pipe(
      map((response: Unit[]) => {
        return response.sort((a, b) => a.unitShortName.localeCompare(b.unitShortName));
      })
    );
  };

  getSalesforceClients(includeChildEntities: boolean = true,
    activeOnly: boolean = true): Observable<RFQSalesForceClient[]> {
    const url = `refdata/rfqSalesForceClient/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<RFQSalesForceClient[]>(url).pipe(
      map((response: RFQSalesForceClient[]) => {
        return response.sort((a, b) => a.salesforceClientId.localeCompare(b.salesforceClientId));
      })
    );
  };

  getSalesforceUsers(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<SalesforceUser[]> {
    const url = `refdata/rfqsalesforceUser/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<SalesforceUser[]>(url).pipe(
      map((response: SalesforceUser[]) => {
        return response.sort((a, b) => a.salesforceUserId.localeCompare(b.salesforceUserId));
      })
    );
  }
  
  getRfqProductDefault(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<RFQProductDefaults[]> {
    const url = `refdata/rfqProductDefaults/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<RFQProductDefaults[]>(url).pipe(
      map((response: RFQProductDefaults[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getRfqLocaleXRef(activeOnly: boolean = true): Observable<RFQLocaleXref[]> {
    const url = `refdata/rfqLocale/all?activeOnly=${activeOnly}`;
    return this.getData<RFQLocaleXref[]>(url).pipe(
      map((response: RFQLocaleXref[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getPremiumProfiles(activeOnly: boolean = true): Observable<RFQPremiumProfiles[]> {
    const url = `refdata/RFQPremiumProfile/all?activeOnly=${activeOnly}`;
    return this.getData<RFQPremiumProfiles[]>(url).pipe(
      map((response: RFQPremiumProfiles[]) => {
        return response.sort((a, b) => a.profileName.localeCompare(b.profileName));
      })
    );
  };

  getRfqAlertTopics( activeOnly: boolean = true): Observable<RFQAlertTopic[]> {
    const url = `refdata/RFQAlertTopic/all?activeOnly=${activeOnly}`;
    return this.getData<RFQAlertTopic[]>(url).pipe(
      map((response: RFQAlertTopic[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getRfqClientAgreements(activeOnly: boolean = true): Observable<RFQClientAgreements[]> {
    const url = `refdata/rfqClientAgreement/all?activeOnly=${activeOnly}`;
    return this.getData<RFQClientAgreements[]>(url).pipe(
      map((response: RFQClientAgreements[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getRfqClientCurrency(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<RFQClientCurrency[]> {
    const url = `refdata/rfqClientCurrency/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<RFQClientCurrency[]>(url).pipe(
      map((response: RFQClientCurrency[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getRfqClientProducts(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<RFQClientProducts[]> {
    const url = `refdata/rfqClientProduct/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<RFQClientProducts[]>(url).pipe(
      map((response: RFQClientProducts[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  

  getRfqRegions(activeOnly: boolean = true): Observable<RFQRegion[]> {
    const url = `refdata/rfqRegion/all?activeOnly=${activeOnly}`;
    return this.getData<RFQRegion[]>(url).pipe(
      map((response: RFQRegion[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getRfqTradingDefinitions(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<RFQTradingDefinition[]> {
    const url = `refdata/rfqTradingDefinition/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<RFQTradingDefinition[]>(url).pipe(
      map((response: RFQTradingDefinition[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getRfqTradingLimits(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<RFQClientTradingLimits[]> {
    const url = `refdata/RFQClientProductLimits/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<RFQClientTradingLimits[]>(url).pipe(
      map((response: RFQClientTradingLimits[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getPhysicalLocations(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<PreciousLocations[]> {
    const url = `refdata/physicalLocation/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<PreciousLocations[]>(url).pipe(
      map((response: PreciousLocations[]) => {
        return response.sort((a, b) => a.locationName.localeCompare(b.locationName));
      })
    );
  }
  

  getTimezones(activeOnly: boolean = true): Observable<CalendarTimezones[]> {
    const url = `refdata/timezone/all?activeOnly=${activeOnly}`;
    return this.getData<CalendarTimezones[]>(`${url}`).pipe(
      map((response: CalendarTimezones[]) => {
        return response.sort((a, b) => a.timezoneShortName.localeCompare(b.timezoneShortName));
      })
    );
  };

  getRegions(activeOnly: boolean = true): Observable<Region[]> {
    const url = `refdata/Region/all?activeOnly=${activeOnly}`;
    return this.getData<Region[]>(`${url}`).pipe(
      map((response: Region[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getCalendars(activeOnly: boolean = true): Observable<Calendar[]> {
    const url = `refdata/calendar/all?activeOnly=${activeOnly}`;
    return this.getData<Calendar[]>(url).pipe(
      map((response: Calendar[]) => {
        return response.sort((a, b) => a.calendarName.localeCompare(b.calendarName));
      })
    );
  };

  getSourceSystems(activeOnly: boolean = true): Observable<SourceSystem[]> {
    const url = `refdata/sourceSystem/all?activeOnly=${activeOnly}`;
    return this.getData<SourceSystem[]>(url).pipe(
      map((response: SourceSystem[]) => {
        return response.sort((a, b) => a.sourceSystemName.localeCompare(b.sourceSystemName));
      })
    );
  };

  getExchanges(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<Exchange[]> {
    const url = `refdata/exchange/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Exchange[]>(url).pipe(
      map((response: Exchange[]) => {
        return response.sort((a, b) => a.exchangeShortName.localeCompare(b.exchangeShortName));
      })
    );
  }
  
  getOrderExpiry(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<OrderExpiry[]> {
    const url = `refdata/orderExpiry/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderExpiry[]>(url).pipe(
      map((response: OrderExpiry[]) => {
        return response.sort((a, b) => a.orderExpiryName.localeCompare(b.orderExpiryName));
      })
    );
  }
  

  getPricingIndex(activeOnly: boolean = true): Observable<PricingIndex[]> {
    const url = `refdata/pricingIndex/all?activeOnly=${activeOnly}`;
    return this.getData<PricingIndex[]>(url).pipe(
      map((response: PricingIndex[]) => {
        return response.sort((a, b) => a.pricingIndexName.localeCompare(b.pricingIndexName));
      })
    );
  };

  getQtyPeriodicity( includeChildEntities: boolean = true,
    activeOnly: boolean = true): Observable<QtyPeriodicity[]> {
    const url = `refdata/qtyPeriodicity/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<QtyPeriodicity[]>(url).pipe(
      map((response: QtyPeriodicity[]) => {
        return response.sort((a, b) => a.qtyPeriodicityName.localeCompare(b.qtyPeriodicityName));
      })
    );
  };

  getProductAvailableMonths(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ProductAvailableMonth[]> {
    const url = `refdata/productAvailableMonth/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ProductAvailableMonth[]>(url).pipe(
      map((response: ProductAvailableMonth[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getProductContracts(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ProductContract[]> {
    const url = `refdata/exchangeCommodityContractXref/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ProductContract[]>(url).pipe(
      map((response: ProductContract[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getPricingPeriodRules(activeOnly: boolean = true): Observable<PricingPeriodRule[]> {
    const url = `refdata/pricingPeriodRule/all?activeOnly=${activeOnly}`;
    return this.getData<PricingPeriodRule[]>(url).pipe(
      map((response: PricingPeriodRule[]) => {
        return response.sort((a, b) => a.periodRuleCode.localeCompare(b.periodRuleCode));
      })
    );
  };

  getOrderTypes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<OrderTypes[]> {
    const url = `refdata/orderType/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderTypes[]>(url).pipe(
      map((response: OrderTypes[]) => {
        return response.sort((a, b) => a.orderTypeName.localeCompare(b.orderTypeName));
      })
    );
  }
  
  getProductOrderTypes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ProductOrderType[]> {
    const url = `refdata/productOrderTypeExpiryXref/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ProductOrderType[]>(url).pipe(
      map((response: ProductOrderType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getProductRingKerbTypes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ProductRingKerb[]> {
    const url = `refdata/ProductRingKerbCode/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ProductRingKerb[]>(url).pipe(
      map((response: ProductRingKerb[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getProductUnits(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ProductUnit[]> {
    const url = `refdata/exchangeCommodityUnitXref/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ProductUnit[]>(url).pipe(
      map((response: ProductUnit[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getOrderStyles(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<OrderStyles[]> {
    const url = `refdata/orderStyle/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderStyles[]>(url).pipe(
      map((response: OrderStyles[]) => {
        return response.sort((a, b) => a.styleName.localeCompare(b.styleName));
      })
    );
  }
  

  getPricingFrequencies(includeChildEntities: boolean = true,
    activeOnly: boolean = true): Observable<PricingFrequency[]> {
      const url = `refdata/pricingFrequency/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<PricingFrequency[]>(url).pipe(
      map((response: PricingFrequency[]) => {
        return response.sort((a, b) => a.pricingFrequencyName.localeCompare(b.pricingFrequencyName));
      })
    );
  };

  getDisplayOptions(): Observable<DisplayOption[]> {
    return this.getData<DisplayOption[]>('refdata/displayOption/all').pipe(
      map((response: DisplayOption[]) => {
        return response.sort((a, b) => a.displayOptionName.localeCompare(b.displayOptionName));
      })
    );
  };

  getOrderTypeContracts(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<OrderTypeContracts[]> {
    const url = `refdata/orderTypeContractXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderTypeContracts[]>(url).pipe(
      map((response: OrderTypeContracts[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getOrderTypeBasis(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<OrderTypeBasis[]> {
    const url = `refdata/orderTypeOrderBasisXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderTypeBasis[]>(url).pipe(
      map((response: OrderTypeBasis[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getUserProfiles(activeOnly: boolean = true): Observable<UserProfile[]> {
    // Construct the URL dynamically based on the activeOnly parameter
    const url = `refdata/userProfile/all?activeOnly=${activeOnly}`;
    return this.getData<UserProfile[]>(url).pipe(
      map((response: UserProfile[]) => {
        return response.sort((a, b) => a.loginId.localeCompare(b.loginId));
      })
    );
  };

  getContracts(activeOnly: boolean = true): Observable<Contract[]> {
     // Construct the URL dynamically based on the activeOnly parameter
     const url = `refdata/contract/all?activeOnly=${activeOnly}`;
    return this.getData<Contract[]>(`${url}`).pipe(
      map((response: Contract[]) => {
        return response.sort((a, b) => a.contractName.localeCompare(b.contractName));
      })
    );
  };

  getCurveModels(activeOnly: boolean = true): Observable<ValuationModel[]> {
    const url = `refdata/valuationModel/all?activeOnly=${activeOnly}`;
    return this.getData<ValuationModel[]>(url).pipe(
      map((response: ValuationModel[]) => {
        return response.sort((a, b) => a.valuationModelName.localeCompare(b.valuationModelName));
      })
    );
  };

  getFxCurves(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<FxCurve[]> {
    const url = `refdata/fxCurveDefinition/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<FxCurve[]>(url).pipe(
      map((response: FxCurve[]) => {
        return response.sort((a, b) => a.curveName.localeCompare(b.curveName));
      })
    );
  }
  
  getIPCDefaults(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<IPCDefaults[]> {
    const url = `refdata/inPriceCommissionDefault/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<IPCDefaults[]>(url).pipe(
      map((response: IPCDefaults[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getForwardCurves(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ForwardCurve[]> {
    const url = `refdata/forwardCurveDefinition/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ForwardCurve[]>(url).pipe(
      map((response: ForwardCurve[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getIPCOperationDefaults(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<IPCOperationDefaults[]> {
    const url = `refdata/inPriceCommissionOperationDefault/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<IPCOperationDefaults[]>(url).pipe(
      map((response: IPCOperationDefaults[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  

  getIPCOperations(activeOnly: boolean = true): Observable<IPCOperation[]> {
    const url = `refdata/InPriceCommissionOperation/all?activeOnly=${activeOnly}`;
    return this.getData<IPCOperation[]>(url).pipe(
      map((response: IPCOperation[]) => {
        return response.sort((a, b) => a.name.localeCompare(b.name));
      })
    );
  };

  getOrderBuySell(activeOnly: boolean = true): Observable<OrderBuySell[]> {
    const url = `refdata/orderBuySell/all?activeOnly=${activeOnly}`;
    return this.getData<OrderBuySell[]>(url).pipe(
      map((response: OrderBuySell[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getIPCValues(activeOnly: boolean = true): Observable<IPCValue[]> {
    const url = `refdata/InPriceCommission/all?activeOnly=${activeOnly}`;
    return this.getData<IPCValue[]>(url).pipe(
      map((response: IPCValue[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getPricingIndexProductCurrency(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<PricingIndexProductCurrency[]> {
    const url = `refdata/pricingIndexProductCurrencyXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<PricingIndexProductCurrency[]>(url).pipe(
      map((response: PricingIndexProductCurrency[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  

  getQuoteTypes(activeOnly: boolean = true): Observable<QuoteType[]> {
    const url = `refdata/pricingQuoteType/all?activeOnly=${activeOnly}`;
    return this.getData<QuoteType[]>(url).pipe(
      map((response: QuoteType[]) => {
        return response.sort((a, b) => a.quoteTypeName.localeCompare(b.quoteTypeName));
      })
    );
  };

  getQuotes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<Quote[]> {
    const url = `refdata/pricingQuote/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<Quote[]>(url).pipe(
      map((response: Quote[]) => {
        return response.sort((a, b) => a.quoteLogicalName.localeCompare(b.quoteLogicalName));
      })
    );
  }
  
  getValuationLiquidContracts(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ValuationLiquidContract[]> {
    const url = `refdata/valuationLiquidContract/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ValuationLiquidContract[]>(url).pipe(
      map((response: ValuationLiquidContract[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getValuationModels(activeOnly: boolean = true): Observable<ValuationModel[]> {
    const url = `refdata/valuationModel/all?activeOnly=${activeOnly}`;
    return this.getData<ValuationModel[]>(url).pipe(
      map((response: ValuationModel[]) => {
        return response.sort((a, b) => a.valuationModelName.localeCompare(b.valuationModelName));
      })
    );
  };

  getExchangeCurrencies(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ExchangeCurrency[]> {
    const url = `refdata/exchangeCurrencyXRef/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ExchangeCurrency[]>(url).pipe(
      map((response: ExchangeCurrency[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getClientOrderBasisBlacklist(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ClientOrderBasisBlacklist[]> {
    const url = `refdata/clientOrderBasisBlacklist/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ClientOrderBasisBlacklist[]>(url).pipe(
      map((response: ClientOrderBasisBlacklist[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getUnitConversions(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<UnitConversions[]> {
    const url = `refdata/unitConversion/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<UnitConversions[]>(url).pipe(
      map((response: UnitConversions[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getCurrencyUnits(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<CurrencyUnitDependencies[]> {
    const url = `refdata/currencyUnitXref/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<CurrencyUnitDependencies[]>(url).pipe(
      map((response: CurrencyUnitDependencies[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getBlotterUserAccessTypes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<BlotterUserAccessType[]> {
    const url = `refdata/blotterUserAccess/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<BlotterUserAccessType[]>(url).pipe(
      map((response: BlotterUserAccessType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getBlotterEODAccessTypes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<BlotterEODAccessType[]> {
    const url = `refdata/blotterEODAccess/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<BlotterEODAccessType[]>(url).pipe(
      map((response: BlotterEODAccessType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getBlotterDefinitions(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<BlotterDefinitionType[]> {
    const url = `refdata/blotterDefinition/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<BlotterDefinitionType[]>(url).pipe(
      map((response: BlotterDefinitionType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getBlotterNameTypes(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<BlotterNameType[]> {
    const url = `refdata/blotterDefinition/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<BlotterNameType[]>(`${url}`).pipe(
      map((response: BlotterNameType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getBlotterSetups(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<BlotterDefinitionType[]> {
    const url = `refdata/blotterSetup/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<BlotterDefinitionType[]>(url).pipe(
      map((response: BlotterDefinitionType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getArbBlotterDefinitions(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ArbBlotterDefinitionType[]> {
    const url = `refdata/arbBlotterDefinition/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ArbBlotterDefinitionType[]>(url).pipe(
      map((response: ArbBlotterDefinitionType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getArbBlotterUserAccessTypes(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<ArbBlotterUserAccessType[]> {
    const url = `refdata/arbBlotterUserAccess/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ArbBlotterUserAccessType[]>(url).pipe(
      map((response: ArbBlotterUserAccessType[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getRoleFunctionMatrixs(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<RoleFunctionMatrix[]> {
    const url = `refdata/rolefunction/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<RoleFunctionMatrix[]>(url).pipe(
      map((response: RoleFunctionMatrix[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getOrderTemplateGroupMemberships(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<OrderTemplateGroupMembership[]> {
    const url = `refdata/OrderTemplateGroupMembership/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderTemplateGroupMembership[]>(url).pipe(
      map((response: OrderTemplateGroupMembership[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getOrderMgtViewGroupMemberships(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<OrderMgtViewGroupMembership[]> {
    const url = `refdata/OrderManagementViewGroupMembership/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<OrderMgtViewGroupMembership[]>(url).pipe(
      map((response: OrderMgtViewGroupMembership[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  getUserGroupMemberships(
    includeChildEntities: boolean = true,
    activeOnly: boolean = true
  ): Observable<UserGroupMembership[]> {
    const url = `refdata/UserGroupMembership/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<UserGroupMembership[]>(url).pipe(
      map((response: UserGroupMembership[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }
  
  
  getBlotterSources(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<BlotterSource[]> {
    const url = `refdata/blotterTradeSource/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<BlotterSource[]>(url).pipe(
      map((response: BlotterSource[]) => {
        return response.sort((a, b) => a.tradeSourceName.localeCompare(b.tradeSourceName));
      })
    );
  };

  getDateRules(activeOnly: boolean = true): Observable<ContractDateRule[]> {
    const url = `refdata/ContractDateRule/all?activeOnly=${activeOnly}`;
    return this.getData<ContractDateRule[]>(url).pipe(
      map((response: ContractDateRule[]) => {
        return response.sort((a, b) => a.dateRuleCode.localeCompare(b.dateRuleCode));
      })
    );
  };

  getPortfolioDefaults(includeChildEntities: boolean = true, 
    activeOnly: boolean = true
  ): Observable<PortfolioDefault[]> {
    const url = `refdata/portfoliodefault/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<PortfolioDefault[]>(url).pipe(
      map((response: PortfolioDefault[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getProdutPortfolios(includeChildEntities: boolean = true, 
    activeOnly: boolean = true
  ): Observable<ProductPortfolio[]> {
    const url = `refdata/productportfolio/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<ProductPortfolio[]>(url).pipe(
      map((response: ProductPortfolio[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getEntityPortfolios(includeChildEntities: boolean = true, 
    activeOnly: boolean = true
  ): Observable<EntityPortfolioWhitelist[]> {
    const url = `refdata/entityportfoliowhitelist/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<EntityPortfolioWhitelist[]>(url).pipe(
      map((response: EntityPortfolioWhitelist[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  }

  getEntityPortfolioOverrides(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<EntityPortfolioOverride[]> {
    const url = `refdata/entityportfolioOverride/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<EntityPortfolioOverride[]>(`${url}`).pipe(
      map((response: EntityPortfolioOverride[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getContractMappingData(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<TrinMomContractMapping[]> {
    const url = `refdata/TrinMomContractMapping/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<TrinMomContractMapping[]>(`${url}`).pipe(
      map((response: TrinMomContractMapping[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getTradeTxType(activeOnly: boolean = true): Observable<TradeTxType[]> {
    const url = `refdata/TradeTxType/all?activeOnly=${activeOnly}`;
    return this.getData<TradeTxType[]>(url).pipe(
      map((response: TradeTxType[]) => {
        return response.sort((a, b) => a.tradeTxTypeName.localeCompare(b.tradeTxTypeName));
      })
    );
  };

  getTrinMomIntegrationAction(activeOnly: boolean = true): Observable<TrinMomIntegrationAction[]> {
    const url = `refdata/TrinMomIntegrationAction/all?activeOnly=${activeOnly}`;
    return this.getData<TrinMomIntegrationAction[]>(url).pipe(
      map((response: TrinMomIntegrationAction[]) => {
        return response.sort((a, b) => a.integrationActionName.localeCompare(b.integrationActionName));
      })
    );
  };

  getHedgeRuleData(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<HedgeRuleDefinition[]> {
    const url = `refdata/HedgeRuleDefinition/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<HedgeRuleDefinition[]>(`${url}`).pipe(
      map((response: HedgeRuleDefinition[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };
 
  getProfiles(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<UserProfile[]> {
    const url = `refdata/userProfile/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<UserProfile[]>(`${url}`).pipe(
      map((response: UserProfile[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };

  getDateFormat( activeOnly: boolean = true): Observable<DateFormat[]> {
    const url = `refdata/DateFormat/all?activeOnly=${activeOnly}`;
    return this.getData<DateFormat[]>(`${url}`).pipe(
      map((response: DateFormat[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };


  getOrderEntryMonthsPref( activeOnly: boolean = true): Observable<	OrderEntryMonthsPref[]> {
    const url = `refdata/OrderEntryMonthsPref/all?activeOnly=${activeOnly}`;
    return this.getData<	OrderEntryMonthsPref[]>(`${url}`).pipe(
      map((response: 	OrderEntryMonthsPref[]) => {
        return response.sort((a, b) => a.id - b.id);
      })
    );
  };


  getMOMBrokerCommRule(includeChildEntities: boolean = true, 
    activeOnly: boolean = true): Observable<MOMBrokerageCommissionRules[]> {
    const url = `refdata/MOMBrokerCommRule/all?includeChildEntities=${includeChildEntities}&activeOnly=${activeOnly}`;
    return this.getData<MOMBrokerageCommissionRules[]>(`${url}`).pipe(
      map((response: MOMBrokerageCommissionRules[]) => {
        return response.sort((a, b) => a.partiName.localeCompare(b.partiName));
      })
    );
  };
}