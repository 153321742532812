import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';


@Component({
    selector: 'app-ai-chat',
    standalone: true,
    templateUrl: './ai-chat.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [OverlayPanelModule, CommonModule, InputTextModule, ButtonModule, FormsModule, TooltipModule],
})
export class AiChatComponent implements OnInit {

    defaultUserId: number = 123;

    messages: any[] = [];

    textContent: string = '';

    uploadedFiles: any[] = [];

    constructor() { }

    setMessage() {

    }

    ngOnInit(): void {
        this.setMessage();
    }

    sendMessage() {
        if (this.textContent == '' || this.textContent === ' ') {
            return;
        }
        else {
            let message = {
                text: this.textContent,
                ownerId: 123,
                createdAt: new Date().getTime(),
            }

            this.messages.push(message);
            this.textContent = '';
        }
    }

    onEmojiSelect(emoji: string) {
        this.textContent += emoji;
    }

    parseDate(timestamp: number) {
        return new Date(timestamp).toTimeString().split(':').slice(0, 2).join(':');
    }
}
