<div class="grid">
    <div class="col-6">
        <app-save-new-view [newView]="newCreatedView" [savedFilters]="savedFilters" [selectedFilter]="selectedFilter" (closeNewView)="closeSaveNewViewDialog()" (saveView)="saveNewView()" 
            (showSaveDialog)="showSaveNewViewDialog" (loadDefault)="loadDefaultView()" (loadSecond)="loadSecondView()">
        </app-save-new-view>
    </div>
    <div class="col-6">
        <igx-input-group type="search">
            <igx-prefix>
                <igx-icon *ngIf="searchText.length === 0">search</igx-icon>
                <igx-icon *ngIf="searchText.length > 0" (click)="clearSearch()">clear</igx-icon>
            </igx-prefix>
        
            <input #search1 id="search1" igxInput placeholder="Search" [(ngModel)]="searchText" 
            (input)="onSearchInputChange($event)" (ngModelChange)="grid.findNext(searchText, caseSensitive, exactMatch)"
            (keydown)="searchKeyDown($event)" />
        
            <igx-suffix *ngIf="searchText.length > 0">
                <div class="resultsText" *ngIf="grid.lastSearchInfo">
                    <span *ngIf="grid.lastSearchInfo.matchInfoCache.length > 0">
                        {{ grid.lastSearchInfo.activeMatchIndex + 1 }} of {{ grid.lastSearchInfo.matchInfoCache.length }}
                        results
                    </span>
                    <span *ngIf="grid.lastSearchInfo.matchInfoCache.length === 0">
                        No results
                    </span>
                </div>
                <div class="chips">
                    <igx-chips-area>
                        <igx-chip (click)="updateSearch()" [color]="caseSensitive? 'lightgrey' : 'rgba(0, 0, 0, .04)'">
                            <span title="Match case">Aa</span>
                        </igx-chip>
                        <igx-chip (click)="updateExactSearch()" [color]="exactMatch? 'lightgrey' : 'rgba(0, 0, 0, .04)'">
                            <u title="Exact match">Ab</u>
                        </igx-chip>
                    </igx-chips-area>
                </div>
                <div class="searchButtons">
                    <button igxIconButton="flat" igxRipple [igxRippleCentered]="true" (click)="grid.findPrev(searchText, caseSensitive, exactMatch)">
                        <igx-icon family="material">navigate_before</igx-icon>
                    </button>
                    <button igxIconButton="flat" igxRipple [igxRippleCentered]="true" (click)="grid.findNext(searchText, caseSensitive, exactMatch)">
                        <igx-icon family="material">navigate_next</igx-icon>
                    </button>
                </div>
            </igx-suffix>
        </igx-input-group>
    </div>
</div>

<div class="col-12 grid-container">
    <igx-grid [data]="trades" #tradeGrid [igxGridState]="options" #dg1 id="dg1" 
    hiddenColumnsText="Hidden" 
    [allowFiltering]="true" 
    [filterMode]="'excelStyleFilter'" 
    [allowAdvancedFiltering]="true"
    columns="true" 
    [moving]="true"
    (columnInit)="onColumnInit($event)">
        <igx-grid-toolbar>
            <igx-grid-toolbar-title>All Trades</igx-grid-toolbar-title>
            <igx-grid-toolbar-actions>
                <igx-grid-toolbar-advanced-filtering></igx-grid-toolbar-advanced-filtering>
                <igx-grid-toolbar-hiding></igx-grid-toolbar-hiding>
                <igx-grid-toolbar-pinning></igx-grid-toolbar-pinning>
                <igx-grid-toolbar-exporter></igx-grid-toolbar-exporter>
            </igx-grid-toolbar-actions>
        </igx-grid-toolbar>
        <igx-column *ngFor="let c of columns"
            [sortable]="c.sortable"
            [editable]="false"
            [hasSummary]="c.hasSummary"
            [filterable]="c.filterable"
            [groupable]="c.groupable"
            [field]="c.field"
            [header]="c.header"
            [dataType]="c.dataType"
            [pinned]="c.pinned"
            [hidden]="c.hidden"
            [resizable]="true">
        </igx-column>
    </igx-grid>
</div>

<p-dialog *ngIf="showTradesDialog" [(visible)]="showTradesDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{ width: '50vw' }"  header="Notes">
    <app-trade-order-notes [tradeOrder]="selectedTrade" (closeNotes)="closeTradeNotesDialog()"></app-trade-order-notes>
</p-dialog>

<p-menu #menu appendTo="body" [model]="contextMenuItems" [popup]="true"></p-menu>

<!-- Templates for setting up the columns in the igx-grid -->
<ng-template pTemplate="body" #id let-trade>
    <td>{{ trade.row.data.id }}</td>
</ng-template>

<ng-template igxCell let-trade="cell" #idTemplate>
    <span style="cursor:pointer" (click)="menu.toggle($event)">
        <ng-template [ngTemplateOutlet]="id" [ngTemplateOutletContext]="{ $implicit: trade }"></ng-template>
    </span>
</ng-template>

<ng-template #lockTemplate let-trade>
    <ng-container>
        <i class="pi" [ngClass]="{'pi-lock': trade.row.data.isLocked == true, 'pi-unlock': trade.row.data.isLocked == undefined}"></i>
    </ng-container>
</ng-template>

<ng-template igxCell let-trade="cell" #isLockedTemplate>
    <span (click)="lockTrade($event)">
        <ng-template [ngTemplateOutlet]="lockTemplate" [ngTemplateOutletContext]="{ $implicit: trade }" ></ng-template>
    </span>
</ng-template>

<ng-template #notesIconTemplate let-trade>
    <ng-container>
        <i class="pi" [ngClass]="{'pi-book': trade.row.data.notes != '', 'pi-comment': trade.row.data.notes == ''}" pTooltip="{{trade.row.data.notes}}"></i>
    </ng-container>
</ng-template>

<ng-template igxCell let-trade="cell" #notesTemplate>
    <span style="cursor:pointer" (click)="showTradeNote(trade)">
        <ng-template [ngTemplateOutlet]="notesIconTemplate" [ngTemplateOutletContext]="{ $implicit: trade }" ></ng-template>
    </span>
</ng-template>

<ng-template igxCell let-cell="cell" #sentenceTemplate>
    <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(cell.row.data.sentence)"></span>
</ng-template>

<ng-template igxCell let-cell="cell" #dateTemplate>
    {{ cell.value | date: (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ?? 'yyyy/MM/dd') + ' HH:mm' }}
</ng-template>