import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { RecentOrdersTradesComponent } from './recent-orders-trades/recent-orders-trades.component';
import { TradesComponent } from './trades/trades.component';
import { NewTradeComponent } from './new-trade/new-trade.component';
import { AuthGuard } from './utilities/auth-guard';
import { NewTicketComponent } from './new-ticket/new-ticket.component';
import { OrdersComponent } from './orders/orders.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { AiChatComponent } from './ai-chat/ai-chat.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
};

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                component: RecentOrdersTradesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'trade/view',
                component: TradesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'trade/new',
                component: NewTradeComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'ticket/new',
                component: NewTicketComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'order/view',
                component: OrdersComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'documentation',
                component: DocumentationComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'chat',
                component: AiChatComponent,
                canActivate: [AuthGuard]
            }
        ],
    },
    {
        path: 'accounts',
        loadChildren: () =>
            import('./accounts/accounts.module').then(
                (m) => m.AccountsModule
            ),
            canActivate: [AuthGuard],
    },
    {
        path: 'ref-data',
        loadChildren: () =>
            import('./ref-data/ref-data.module').then(
                (m) => m.RefDataModule
            ),
            canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./components/auth/auth.module').then(
                (m) => m.AuthModule
            ),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./components/auth/login/login.module').then(
                (m) => m.LoginModule
            ),
    },
    {
        path: 'notfound',
        loadChildren: () =>
            import('./components/notfound/notfound.module').then(
                (m) => m.NotfoundModule
            ),
    },
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
